module.exports = {
  methods: {
    dateFormat(val) {
      if (val && val.length >= 8) {
        return `${val.substring(0, 4)}-${val.substring(4, 6)}-${val.substring(6, 8)}`
      } else {
        return ''
      }
    },
    moneyFormat(val) {
      if (val === 0 || val) {
        const reg = /(^[+-]?\d+)(\d{3})/;
        let n = (val + '');
        while (reg.test(n))
          n = n.replace(reg, '$1' + ',' + '$2');

        return n
      } else {
        return ''
      }
    },
    registerNoFormat(val) {
      if (val && val.length >= 10) {
        return `${val.substring(0, 6)}-${val.substring(6, 7)}******`
      } else {
        return ''
      }
    },
    bizRegisterNoFormat(val) {
      if (val && val.length >= 10) {
        return `${val.substring(0, 3)}-${val.substring(3, 5)}-${val.substring(5)}`
      } else {
        return ''
      }
    }
  }
}
