import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      userCode: null,
      userName: null,
      authCode: null,
      uniqCode: null,
      userPart: null,
      userClass: null,
      email: null,
      cellNo: null
    },
    menuList: [],
    isLogin: false
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    menuList(state) {
      return state.menuList
    },
    isAdmin(state) {
      return state.userInfo.authCode.indexOf('ROLE_ADMIN') > -1
    },
    isManager(state) {
      return state.userInfo.authCode.indexOf('ROLE_MANAGER') > -1
    },
    isTeamLeader(state) {
      return state.userInfo.authCode.indexOf('ROLE_TEAMLEADER') > -1
    }
  },
  actions: {
    signIn ({dispatch}, {userId, userPw}) {
      return axios.post('/api/sign/signin', {userId: userId, password: userPw})
      .then(({data}) => {
        if (data && data !== '') {
          localStorage.setItem('access_token', data)
          return dispatch('getUserInfo')
        } else {
          throw new Error()
        }
      })
    },
    signInSso ({dispatch}) {
      return new Promise((resolve, reject) => {
        if (process.env.NODE_ENV === 'production') {
          const token = localStorage.getItem('sso_token')
          const accessToken = localStorage.getItem('access_token')
          if (token) {
            return axios.post(`/api/auth/signInSso`, {token: token})
                .then(({data}) => {
                  if (data && data.accessToken) {
                    localStorage.setItem('access_token', data.accessToken)
                    return resolve(dispatch('getUserInfo'))
                  } else {
                    reject('로그인에 실패하였습니다')
                  }
                })
                .finally(() => {
                  localStorage.removeItem('sso_token')
                })
          } else if (accessToken) {
            resolve(dispatch('getUserInfo'))
          } else {
            reject('비정상적인 접근입니다.1')
          }
        } else {
          return axios.post(`/api/auth/signInSso`, {token: '$2a$10$fk3iytV9Crlbx1t/.RxHD.Q1VSOIaxvORgmt.gipLtnh8zeC5ryNy'})
          // return axios.post(`/api/auth/signInSso`, {token: 'zzz'})
          .then(({data}) => {
            if (data && data.accessToken) {
              localStorage.setItem('access_token', data.accessToken)
              return resolve(dispatch('getUserInfo'))
            } else {
              reject('로그인에 실패하였습니다')
            }
          })
        }
      })
    },
    signOut ({commit}) {
      localStorage.removeItem('access_token')
      return commit('signOut')
    },
    isLogin({commit}) {
      const token = localStorage.getItem('access_token')
      if (token) {
        commit('loginSuccess', {userId: token})
        return Promise.resolve()
      } else {
        return Promise.reject('로그인 후 이용해 주세요.')
      }
    },
    getUserInfo({commit}) {
      let token = localStorage.getItem('access_token')

      if (token) {
        let config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }

        return axios.get('/api/auth/info', config)
        .then(({ data }) => {
          if (!data.errorMessage) {
            return commit('loginSuccess', data)
          } else {
            return Promise.reject(data.errorMessage)
          }
        })
      }
    }
  },
  mutations: {
    loginSuccess (state, authInfo) {
      state.userInfo = {
        userCode: authInfo.authUser.userCode,
        userName: authInfo.authUser.userName,
        authCode: authInfo.authUser.authCode,
        userPart: authInfo.authUser.userPart,
        userClass: authInfo.authUser.userClass,
        email: authInfo.authUser.email,
        cellNo: authInfo.authUser.cellNo
      }
      state.menuList = authInfo.menuList
      state.isLogin = true
    },
    signOut (state) {
      state.userInfo = {}
      state.menuList = []
      state.isLogin = false
    }
  }
})
